<template>
  <div>
    <s-title :text="$t('profile-here')" />
  </div>
</template>

<script>
export default {
  name: 'Profile'
}
</script>
